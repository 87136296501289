import React, { useEffect, useReducer, useRef } from 'react'
import axios from 'axios'

import { Legend } from '../components/legend'
import StatText from '../components/stat-text'
import Content from '../components/content'
import Section from '../components/section'
import Counter from '../components/counter'
import Quotes from '../components/quotes'
import Layout from '../components/layout'
import Image from '../components/image'
import { FadeTop } from '../components/Transitions'
import quoteImage from '../images/quotes.png'
import i18n from '../i18n.json'
import { Pie, Chart } from 'react-chartjs-2'
import { BASE_URL } from "../constants"

Chart.defaults.global.legend.display = false

const backgroundColors = ['#05C170', '#7DDF64', '#f9be00', '#FF7E6B', '#E3170A']

const getStats = id => {
  return axios(BASE_URL + '/response/trends/assurance', {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })
}

const getColorClass = index => {
  const colors = [
    'text-greeny',
    'text-sky',
    'text-orangy',
    'text-secondary',
    'text-blues'
  ]
  return colors[index]
}

const initialState = {
  width: 0,
  isRequestFailed: false,
  stats: [],
  quotes: [],
  indexQuote: 0,
  totalAnswered: 0,
  totalFeedback: 0,
  answer1Feedback: 0,
  answer2Feedback: 0,
  satisfaciton: []
}

function reducer (state, action) {
  switch (action.type) {
    case 'setWidth':
      state.width = action.value
      return { ...state }
    case 'setStats':
      state.stats = action.value
      return { ...state }
    case 'setAnswer1Feedback':
      state.answer1Feedback = action.value
      return { ...state }
    case 'setSatisfaction':
      state.satisfaction = action.value
      return { ...state }
    case 'setRequestError':
      state.isRequestFailed = true
      return { ...state }
    case 'setAnswer2Feedback':
      state.answer2Feedback = action.value
      return { ...state }
    case 'setTotalAnswered':
      state.totalAnswered = action.value
      return { ...state }
    case 'setTotalFeedback':
      state.totalFeedback = action.value
      return { ...state }
    case 'setQuotes':
      state.quotes = action.value
      return { ...state }
    case 'decrementQuote':
      state.indexQuote -= 1
      if (state.indexQuote < 0) state.indexQuote = state.quotes.length - 1
      return { ...state }
    case 'incrementQuote':
      state.indexQuote += 1
      if (state.indexQuote > state.quotes.length - 1) state.indexQuote = 0
      return { ...state }
    default:
      return { ...state }
  }
}

const Assurance = () => {
  const wrapper = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  const getWidth = () => {
    const pie = document.querySelector('.pie-wrapper')
    if (!pie) return 300
    const w = pie.clientWidth / 1.5
    return w
  }

  const incrementQuote = () => {
    dispatch({ type: 'incrementQuote' })
  }

  const decrementQuote = () => {
    dispatch({ type: 'decrementQuote' })
  }

  useEffect(() => {
    // Set a load effect until apii call have finish to load
    document.body.classList.add('loader')

    // Get width for set width of graphs even in resize mode
    window.addEventListener('resize', () => {
      const newWidth = getWidth()
      dispatch({ type: 'setWidth', value: newWidth })
    })

    // Autoplay for quotes
    setInterval(() => {
      dispatch({ type: 'incrementQuote' })
    }, 7000)

    // Autoplay for graphs
    setInterval(() => {
      const graphs = Array.from(document.getElementsByClassName('graph'))
      for (const [index, graph] of graphs.entries()) {
        var previousIndex = (index === 0) ? graphs.length - 1 : index - 1
        if (graphs[previousIndex].classList.contains('show-graph')) {
          graphs[previousIndex].classList.remove('show-graph')
          graph.classList.add('show-graph')
          break
        }
      }
    }, 7000)

    // Get real stats
    getStats()
      .then(res => {
        document.body.classList.remove('loader')
        if (res.data) {
          if (res.data.stats) {
            res.data.stats = res.data.stats.map(item => {
              return {
                title: i18n.graphsTitles[item.name],
                series: item.values
                  .sort((a, b) => b - a)
                  .map((val, index) => ({
                    label: i18n.graphsLabels[item.name][index],
                    value: val,
                    className: getColorClass(index)
                  })),
                data: {
                  labels: item.values.map(
                    (el, i) => i18n.graphsLabels[item.name][i]
                  ),
                  datasets: [
                    {
                      data: item.values.sort((a, b) => b - a),
                      backgroundColor: backgroundColors,
                      options: {
                        legend: { display: false }
                      }
                    }
                  ]
                }
              }
            })
            dispatch({ type: 'setStats', value: res.data.stats })
            dispatch({ type: 'setTotalAnswered', value: res.data.totalAnswers })
            // dispatch({ type: "setSatisfaction", value: res.data.satisfaction })
            dispatch({ type: 'setQuotes', value: res.data.answers })
            dispatch({ type: 'setAnswer1Feedback', value: res.data.answer1Feedback })
            dispatch({ type: 'setAnswer2Feedback', value: res.data.answer2Feedback })
            dispatch({
              type: 'setTotalFeedback',
              value: res.data.totalFeedback
            })
            const newWidth = getWidth()
            dispatch({ type: 'setWidth', value: newWidth })
          }
        }
        // console.log(state)
      })
      .catch(() => {
        document.body.classList.remove('loader')
        dispatch({ type: 'setRequestError' })
      })
    // Get benchamrks about surveys
  }, [])

  return (
    <Layout>
      {state.isRequestFailed && (
        <FadeTop>
          <div className='z-60 w-full lg:w-2/5 fixed bottom-0 flex justify-end right-0'>
            <div className='w-full text-lg font-bold  px-8 py-4 text-white bg-greeny m-12'>
              Sembra che attualmente non sia possibile recuperare i dati. Ma non
              preoccuparti, i nostri tecnici stanno già lavorando per risolvere
              il problema. Ti invitiamo quindi a riprovare più tardi.
            </div>
          </div>
        </FadeTop>
      )}
      <Section id='section-00' className='w-3/5'>
        <Content>
          <div className='z-0 w-full overflow-hidden h-full absolute inset-0'>
            <div className='w-full h-full lg:fixed'>
              <Image className='object-cover w-full h-full' />
            </div>
          </div>
          <div className='z-30 relative text-white border-solid border-0'>
            <div className='mb-12 pt-8'>
              <strong className='text-6xl'>
                {!state.isRequestFailed ? (
                  <Counter value={state.totalFeedback} />
                ) : (
                  '?'
                )}
              </strong>
              <StatText>{i18n.sections['00'].feedbackLeaved}</StatText>
            </div>
            <div className='mb-12'>
              <strong className='text-6xl'>
                {state.isRequestFailed ? (
                  '?'
                ) : (
                  <Counter
                    formatValue={n => parseFloat(n).toFixed(1) + '%'}
                    value={state.answer1Feedback}
                  />
                )}
              </strong>
              <StatText>{i18n.sections['03'].clientHappy}</StatText>
            </div>
            <div className='mb-12'>
              <strong className='text-6xl'>
                {state.isRequestFailed ? (
                  '?'
                ) : (
                  <Counter
                    formatValue={n => parseFloat(n).toFixed(1) + '%'}
                    value={state.answer2Feedback}
                  />
                )}
              </strong>
              <StatText>{i18n.sections['03'].easyHelp}</StatText>
            </div>
            <div className='quotes-container'>
              {(state.quotes && state.quotes.length && (
                <FadeTop>
                  <div>
                    <img src={quoteImage} alt='quote logo' />
                    <StatText>{i18n.question}</StatText>
                    <Quotes
                      quotes={state.quotes}
                      selected={state.indexQuote}
                      onPrevQuote={decrementQuote}
                      onNextQuote={incrementQuote}
                    />
                  </div>
                </FadeTop>
              )) ||
                ''}
            </div>
          </div>
        </Content>
      </Section>
      <Section id='section-01' className='w-2/5 h-full'>
        <Content>
          <div ref={wrapper} className='graphs-container'>
            {!state.isRequestFailed ? (
              state.stats.map((graph, index) => (
                <FadeTop key={'graph' + index} className={`graph w-full ${index === 0 ? 'show-graph' : ''}`}>
                  <div>
                    <h3 className='font-bold text-secondary uppercase pb-4 mb-10 text-right border-0 border-b-2 border-solid text-xl border-secondary'>
                      {graph.title}
                    </h3>
                    <div className='flex flex-col flex-col-reverse items-center'>
                      <div className='w-full mt-10'>
                        <Legend series={graph.series} />
                      </div>
                      <div className='w-full pie-wrapper flex items-center justify-center'>
                        <div>
                          {state.width > 0 ? (
                            <Pie
                              width={state.width}
                              height={state.width}
                              data={graph.data}
                              options={{
                                maintainAspectRatio: true,
                                responsive: true,
                                tooltips: { show: false }
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeTop>
              ))
            ) : (
              <FadeTop>
                <div className='flex justify-center text-gray-400 flex-col items-center text-center'>
                  <p className='text-2xl flex justify-center items-center flex-col font-bold'>
                    <span className='block rounded-full w-64 h-64 mb-8  bg-gray-300 text-white flex items-center text-7xl justify-center'>
                      0%
                    </span>
                    <span>
                      Non è stato possibile generare i grafici.
                      <br />
                      Riprova più tardi.
                    </span>
                  </p>
                </div>
              </FadeTop>
            )}
          </div>
        </Content>
      </Section>
    </Layout>
  )
}

export default Assurance
